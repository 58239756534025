import { useState, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";

import EditLinkImageToImageIpaImages from "./EditLinkImageToImageIpaImages";
import FolderAndFile from "../../FolderAndFile/FolderAndFile";

import { useStore } from "../../../../hook-store/store";
import { isForImageToImageFile } from "../../../../utils/bucket/image-file-edit-util";


import { drawDreamerLink } from "../../../../App";

import { marks } from "../../../../utils/marks";
import classes from "./EditLinkModal.module.css";

function EditLinkImageToImage(props) {
  const {
    smallImageUrl,
  } = props;

  // console.log('FileActions.js-props', props, object.Key);
  const [t] = useTranslation("translation");

  const [store, dispatch] = useStore();
  const { 
    editLinkModalData,
    imageUrlList,
    allBucketObjects,
   } = store.bucketObjectStore;
  // const { allObjectsMetaList } = store.objectMetadataStore;
  const { gLoading } = store.uiStore;

  
  const [ipaImageKeys, setIpaImageKeys] = useState([]);
  const [paramData, setParamData] = useState({
      image: {
        key: editLinkModalData.key,
        mimeType: editLinkModalData.mimeType,
      },
      ipaImages: []
    });
  const [showSelectIpaImage, setShowSelectIpaImage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // console.log('paramData', paramData);

  useEffect(() => {
    if (gLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [gLoading]);

  const addIpaImageKeysHandler = (file) => {
    // console.log(file);
    if (ipaImageKeys.length >= 1) {
      return;
    }

    if (!file.mimeType || !isForImageToImageFile(file.mimeType)) {
      return;
    }

    const ipaKeys = ipaImageKeys;

    const imageUrlObj = imageUrlList.find(element => {
      return element.key === file.Key;
    });


    const addedKeys = ipaKeys.concat({
      id: Math.random(),
      key: file.Key,
      imageUrlObj: imageUrlObj,
      mimeType: file.mimeType,
    });

    const paramIpaKeys = addedKeys.map(ele => {
      return {
        id: ele.id,
        key: ele.key,
        mimeType: ele.mimeType,
      }
    });

    // const paramIpaKeys = [];

    // for (const ele of addedKeys) {
    //   const ipaImageObject = allBucketObjects.find(object => {
    //     return object.Key === ele.key;
    //   });

    //   let mimeType = '';

    //   if (ipaImageObject?.mimeType) {
    //     mimeType = ipaImageObject.mimeType;
    //   }

    //   paramIpaKeys.push({
    //     key: ele.key,
    //     mimeType: mimeType,
    //   });
    // }

    setIpaImageKeys(addedKeys);

    setParamData({
      image: {
        key: editLinkModalData.key,
        mimeType: editLinkModalData.mimeType,
      },
      ipaImages: paramIpaKeys
    })

    dispatch('SHOW_NOTIFICATION', {
      status: '',
      title: 'image added',
      message: '',
    });

    setTimeout(() => {
      dispatch('CLEAR_NOTIFICATION');
    },1000 * 2);

  };


  const targetUrl = `${drawDreamerLink}/?keyData=${JSON.stringify(paramData)}&imagePhotoUserId=${localStorage.getItem('userId')}&openApp=imageToImageGeneration`
  
  let editLinkImageToImageBody;

  editLinkImageToImageBody = (
    <div>
      {/* <div>
        {JSON.stringify(paramData)}
      </div> */}
      <div className={classes.editLinkSection}>
        <div className={classes.editLinkTitle}>
          open file in Image to Image Generation
        </div>
      </div>
      <div className={classes.editLinkSection}>
        {/* <div>image</div> */}
        <img className={classes.editLinkImage}
          src={smallImageUrl} 
        />
      </div>

      {ipaImageKeys.length === 0 && (
        <div className={classes.editLinkSection}>
          <a 
            href={targetUrl}
            target="_blank" rel="noreferrer noopener"
            // style={}
          >
            <button className="btnBase">
              open file in Image to Image Generation
            </button>
          </a>
        </div>
      )}
    
      <div className={classes.editLinkSection}>
        <div>
          <span 
            onClick={() => {
              if (!isLoading && ipaImageKeys.length === 0) {
                setShowSelectIpaImage(!showSelectIpaImage);
              }
            }}
          >
            (optional) select image prompt (jpeg or png file) {marks.triangleDown} 
          </span>
          {showSelectIpaImage && (
            <div className={classes.editLinkSection}>
              <EditLinkImageToImageIpaImages 
                ipaImageKeys={ipaImageKeys}
                setIpaImageKeys={setIpaImageKeys}
                paramData={paramData}
                setParamData={setParamData}
                targetUrl={targetUrl}
              />
            </div>
          )}
        </div>
      </div>
      {showSelectIpaImage && (
        <div className={classes.editLinkSection}>
          {/* <div>select-image-for-image-prompt</div> */}
          <FolderAndFile 
            onFileClick={addIpaImageKeysHandler}
            withImageLink={true}
            withAllLink={false}
            withCheckbox={false}
            getUploadSelectedHandler={() => {}}
            filterMimeTypeList={['image/png', 'image/jpeg']}
          />
        </div>
      )}

    </div>
  );

  return (
    <Fragment>
      {/* {isLoading && <div>loading...loading...</div>} */}
      {editLinkImageToImageBody}
    </Fragment>
  );
}

export default EditLinkImageToImage;
