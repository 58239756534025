import { useState, useEffect, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { useStore } from '../../../../hook-store/store';

// import * as bucketObjectUtils from '../utils/bucket-object-util';
// import * as fileMoveUtils from '../../utils/file-move-util';
import * as bucketObjectUtils from '../../../../utils/bucket/bucket-object-util';

import { BACKEND_URL } from '../../../../App';

import classes from './FileNameChange.module.css';

function FileNameChange(props) {

  const {
    setClickedActionInfoHandler,
    object,
    // isLoading,
  } = props;

    // console.log('FileActions.js-props', props, object.Key);
  const [t] = useTranslation('translation');

  const [store, dispatch] = useStore();
  // console.log('store-BucketObjects.js', store);
  // const currentPrefix = store.bucketObjectStore.currentPrefix;
  const bucketName = store.bucketObjectStore.bucketName;
  const selectedFileKeys = store.bucketObjectStore.selectedFileKeys;
  // const movePrefix = store.bucketObjectStore.movePrefix;
  // const movePrefixes = store.bucketObjectStore.movePrefixes;
  const topLevels = store.bucketObjectStore.topLevels;

  const gLoading = store.uiStore.gLoading;


  const [newNameKey, setNewNameKey] = useState('');
  const [inputError, setInputError] = useState('');
  const [trimedInput, setTrimedInput] = useState('');

  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    if (gLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  },[gLoading]);

  useEffect(() => {
    const oriName = bucketObjectUtils.getOriginalFileNameFromKey(object.Key);
    let oriSplit = oriName.split('.');
    oriSplit.pop();
    // console.log('oriSplit', oriSplit, oriSplit.join('.').trim());
    setTrimedInput(oriSplit.join('.').trim());
  },[]);


  const nameChangeInputHandler = (event, key) => {
    setInputError('');
    console.log(event.target.value, key);
    // let newName = event.target.value.trim().replace(/\s/g, '');
    let newName = event.target.value;

    if (newName.includes('/')) {
      setInputError('name should not have "/"');
    }

    if (newName.length > 100) {
      setInputError('maximum name length is 100');
    }

    if (setTrimedInput && !newName) {
      setInputError('name is required');
    }

    setTrimedInput(newName);

    const timeEndIndex = key.indexOf('Z');
    const originalName = key.substring(timeEndIndex+2);
    console.log(originalName, originalName.split('.')[0]);
    console.log(key.split('Z')[0] + 'Z-' + newName + '.' + key.split('.').pop());
    setNewNameKey(key.split('Z')[0] + 'Z-' + newName + '.' + key.split('.').pop());
    
    // const extLength = key.split('.').pop().length;
    // const noExt = originalName.substring(0, originalName.length - (extLength + 1));
    // console.log(key.split('.').pop(), extLength, noExt, originalName.substring(originalName.length - (extLength + 1)));
    
    // console.log(key.split('Z')[1].split('.')[0].substring(1));
  }

  const filenameChangeHandler = async (bucket, oldKey, newKey, objectList) => {
    try {
      dispatch('SET_GLOADING', true);

      let resultsForAction = {
        fileNameChange: null,
      };

      const timeEndIndex = newKey.indexOf('Z');
      const newFileName = newKey.substring(timeEndIndex+2);
  
      console.log('newFileName', newFileName);
  
      // console.log('objectList', objectList);
      
      if (!newFileName) {
        throw new Error('file name is required.');
      }

      for (const object of objectList) {
        const fullFileName = object.Key.split('/')[object.Key.split('/').length -1];
        const timeEndIndex = fullFileName.indexOf('Z');
        const fileName = fullFileName.substring(timeEndIndex+2);
  
        if (fileName === newFileName) {
          throw new Error('same name file exist.');
        }
      }

      const result = await bucketObjectUtils.changeFilename(
        BACKEND_URL,
        localStorage.getItem('token'),
        bucket,
        oldKey,
        newKey,
        objectList,
      );

      console.log(result);

      resultsForAction.fileNameChange = result;

      if (result) {

        const changeTopLevels = topLevels;
  
        const oldIndex = changeTopLevels.findIndex(object => {
          return object.Key === oldKey;
        });
  
        changeTopLevels[oldIndex].Key = newKey;
  
        console.log(oldIndex, changeTopLevels);
        dispatch('SET_TOPLEVELS', changeTopLevels);


      }

      
      dispatch('SET_OBJECTACTION', {
        actionType: 'change-file-name',
        delete: true,
        add: true,
        result: resultsForAction,
      });

      dispatch('SET_DATAFORMETADATA', result.dataForMetadata);


      dispatch('SHOW_NOTIFICATION', {
        status: '',
        title: 'file name changed',
        message: '',
      });

      setTimeout(() => {
        dispatch('CLEAR_NOTIFICATION');
      },1000 * 3);

      dispatch('SET_GLOADING', false);

    } catch(err) {
      console.log(err);

      dispatch('SHOW_NOTIFICATION', {
        status: 'error',
        title: `file name change failed. ${err.message && err.message}`,
        message: '',
      });

      setTimeout(() => {
        dispatch('CLEAR_NOTIFICATION');
      },1000 * 10);
      
      dispatch('SET_GLOADING', false);
    }
  };

  let fileNameChangeBody;

  fileNameChangeBody = (
    <div>
      
    <input className={`inputBase ${classes.fileNameInput}`}
      style={{ width: "80%" }}
      type="text"
      value={trimedInput}
      onChange={(event) => { nameChangeInputHandler(event, object.Key); }}
      autoFocus
    />
    <span>.{object.Key && object.Key.split('.').pop()}</span>
    <div>{inputError}</div>
    <div className={classes.fileNameChangeButtons}>
      <button className="btnBase"
        disabled={isLoading}
        // name="change-file-name"
        onClick={(event) => {
          if (!isLoading) {
            setClickedActionInfoHandler(null);
          }
        }}
      >
        {t('fileAction.05', 'Cancel')}
      </button>
      <button className="btnBase"
        disabled={isLoading || inputError || !trimedInput}
        name="change-file-name"
        onClick={(event) => {
          if (!isLoading) {
            filenameChangeHandler(bucketName, object.Key, newNameKey, topLevels);
            setClickedActionInfoHandler(event, object.Key);
          }
        }}
      >
        {t('fileAction.06', 'Change Name')}
      </button>
    </div>

  </div>
  )


  return (
    <Fragment>
      {/* {isLoading && <div>loading...loading...</div>} */}
      {fileNameChangeBody}
    </Fragment>
  );
}

export default FileNameChange;
